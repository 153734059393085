<template>
  <div>
    <my-nav-bar
      title="调货详情"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container list">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-for="(transGoods, index) in list" :key="index" class="goods">
        <div>
          <van-image :src="transGoods.image" class="goods-image" />
        </div>
        <div class="goods-info">
          <div class="goods-name">{{ transGoods.name }}</div>
          <div class="goods-size">规格：1 * {{ transGoods.size }}</div>
          <div class="goods-quantity">
            <span>数量: </span>
            <span v-if="transGoods.large_volume">
              {{ transGoods.large_volume }}{{ transGoods.large_unit }}
            </span>
            <span v-if="transGoods.least_volume">
              {{ transGoods.least_volume }}{{ transGoods.least_unit }}
            </span>
          </div>
          <div class="btn">
            <van-button type="primary" size="mini" @click="handleOutConfirm(transGoods)">确认出库</van-button>
          </div>
          <div v-if="transGoods.out_finished_at" class="finished-logo">
            <van-image :src="require('@/assets/images/yichuku.png')" />
          </div>
        </div>
      </div>
    </div>
    <div class="fixed-bottom-btn">
      <van-button :disabled="notFinished" block square @click="handleFinished">出库完成</van-button>
    </div>
  </div>
</template>

<script>
import { getTransOutDetail, finishTransOut, getBusinessOrderDetail, finishBusinessOrder, transGoodsOutConfirm } from '@/api/trans_out'
import myNavBar from '@/components/my-nav-bar'
import { now, sortByKey } from '@/utils'
export default {
  components: { myNavBar },
  data() {
    return {
      list: [],
      showEmpty: false,
      transOrderId: this.$route.query.trans_order_id,
      orderId: this.$route.query.order_id
    }
  },
  computed: {
    notFinished() {
      const filters = this.list.filter(transGoods => !transGoods.out_finished_at)
      return filters.length > 0
    }
  },
  created() {
    if (this.transOrderId) {
      getTransOutDetail(this.transOrderId).then(res => {
        this.list = res.data
        this.showEmpty = this.list.length === 0
      })
    } else {
      const params = {
        order_id: this.orderId
      }
      getBusinessOrderDetail(params).then(res => {
        this.list = res.data
        this.showEmpty = this.list.length === 0
      })
    }
  },
  methods: {
    handleFinished() {
      this.confirm('确定已出库完成吗？', '提示').then(() => {
        this.beginLoad()
        if (this.transOrderId) {
          finishTransOut(this.transOrderId).then(() => {
            this.endLoad()
            this.$router.back()
          })
        } else {
          const data = {
            order_id: this.orderId
          }
          finishBusinessOrder(data).then(() => {
            this.endLoad()
            this.$router.back()
          })
        }
      })
    },
    handleOutConfirm(transGoods) {
      this.beginLoad()
      const data = {
        trans_order_id: this.transOrderId,
        trans_goods_id: transGoods.id
      }
      transGoodsOutConfirm(data).then(res => {
        this.success(res.msg)
        transGoods.out_finished_at = now()
        this.list.sort(sortByKey('out_finished_at'))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
    padding-bottom: calc(constant(safe-area-inset-bottom) + 50px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 50px);
  }
  .goods {
    position: relative;
    margin-bottom: 10px;
    background-color: #fff;
    display: flex;
    padding: 10px;
  }
  .goods-info {
    margin-left: 10px;
    width: 100%;
  }
  .goods-image {
    width: 80px;
    height: 80px;
  }
  .goods-name {
    font-size: 15px;
  }
  .goods-size, .goods-quantity {
    font-size: 13px;
    color: #989898;
    margin-top: 3px;
  }
  .btn {
    text-align: right;
  }
  .finished-logo {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
  }
</style>
